import Vue from 'vue'
import IdleVue from "idle-vue";
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue,  IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const moment = require('moment')
require('moment/locale/de')
Vue.use(require('vue-moment'), {
    moment
});

Vue.config.productionTip = false

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 300000, // 5 min
});

new Vue({
  router,
  store,
  render: h => h(App),
  onIdle() {
    if( router.currentRoute && router.currentRoute.name != "companies") {
      router.push('/');
    } else {
      router.go(router.currentRoute);
    }

  },
}).$mount('#app')
