<template>
<div id="events">

  <b-row class="maincontent">
    <b-col>
    </b-col>

    <b-col cols="10">
      <div class="maininnercontent scrollbar scrollbar-black">
      <div class="accordion" role="tablist">
        <div v-if="this.filteredEvents == null || this.filteredEvents.length==0" class="no-events">Heute finden keine Events im Hause statt.</div>
        <b-card no-body v-for="event in this.filteredEvents" :key="event.id" class="accordion-element">
          <b-card-header header-tag="header" role="tab"  v-b-toggle="'accordion-'+event.id" class="accordion-header">
            {{event.start | moment("HH.mm")}} Uhr, <span v-if="event.organizer">{{event.organizer}}, </span>{{event.name}}
          </b-card-header>
          <b-collapse :id="'accordion-'+event.id" accordion="events-accordion" role="tabpanel" class="accordion-content">
            <b-row no-gutters>
              <b-col md="9">
                <b-card-body class="m-3">
                  <b-media>
                    <template #aside>
                      <b-img v-if="event.image" :src="apiurl+event.image" class="teaser-image mr-5"></b-img>
                    </template>


                    <b-card-text v-if="event.start">
                      <b class="headline">{{event.start | moment("dddd, Do MMMM")}}, <br>{{event.start | moment("HH.mm")}} Uhr</b><br/>
                    </b-card-text>

                    <!--
                    <b-card-text v-if="event.end">
                      <b class="headline">Ende</b><br/> {{event.end}}
                    </b-card-text>
                    -->

                    <b-card-text v-if="event.buildingEntity">
                      <b class="headline">Ort</b><br/>
                      {{event.buildingEntity.name}}<br/> {{event.buildingEntity.location}}
                    </b-card-text>
                    <b-card-text v-else>
                      <b class="headline">Ort</b><br/> {{event.alternativeLocation}}
                    </b-card-text>

                  </b-media>

                  <b-card-text v-if="event.description" class="description mt-4">
                    {{event.description}}
                  </b-card-text>


                </b-card-body>
              </b-col>
              <b-col md="3">
                <b-card-img v-if="event.buildingEntity && event.buildingEntity.floorPlan" :src="apiurl+event.buildingEntity.floorPlan.floorPlan" class="floorplan-image" alt="floorplan" ></b-card-img>
                <b-card-img v-else-if="event.alternativeFloorPlan" :src="apiurl+event.alternativeFloorPlan.floorPlan" class="floorplan-image" alt="floorplan" ></b-card-img>
              </b-col>
            </b-row>
          </b-collapse>
        </b-card>
      </div>
      </div>
    </b-col>
    <b-col >
    </b-col>

  </b-row>

</div>

</template>

<script>


export default {
  name: 'events',
  components: {

  },
  created: function () {
    this.$store.dispatch('fetchEvents');
  },
  data () {
    return {

    }
  },
  methods: {

  },
  computed: {
    events () {
      return ( this.$store.state.events ) ?  this.$store.state.events : []
    },
    filteredEvents () {
      return this.events.filter( event => {
        var today = new Date();
        today.setHours(0,0,0,0);
        var start = new Date(event.start);
        var end = ( event.end != null ) ? new Date(event.end) : null;

      // START HEUTE
      if ( start >= today) return true;

      // START in Vergangenheit, kein END
      if( !end ) return false;

      // START in Vergangenheit, END heute oder in Zukunft?
      return end >= today;

      });
    },
    apiurl () {
      return process.env.VUE_APP_API_URL;
    }
  }
}
</script>

<style>
#events .maincontent {
  height: 1438px;
  background: var(--unnamed-color-6C3536) 0% 0% no-repeat padding-box;
  background: #6C3536 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 100px;
  padding-bottom: 100px;
}
#events .accordion-element:nth-child(even) .accordion-header {
  background: var(--unnamed-color-523131) 0% 0% no-repeat padding-box;
  background: #523131 0% 0% no-repeat padding-box;

}
#events .accordion-element:nth-child(odd) .accordion-header{
  background: var(--unnamed-color-3b2525) 0% 0% no-repeat padding-box;
  background: #3b2525 0% 0% no-repeat padding-box;
}
#events .accordion-header.not-collapsed {
  background: var(--unnamed-color-cf3c42) 0% 0% no-repeat padding-box !important;
  background: #CF3C42 0% 0% no-repeat padding-box !important;
}

#events .no-events {
  margin-top: 100px;
  font-size: 36px;
  font-family: "Akko Rounded Pro, Medium";
  color: #ffffff;
}

#events .teaser-image {
  width: 240px;
  height: 210px;
  object-fit: contain;
}

</style>
