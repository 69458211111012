<template>
<div id="entities">
  <b-row class="maincontent">
    <b-col>
    </b-col>

    <b-col cols="10">
      <div class="maininnercontent scrollbar scrollbar-black">
      <div class="accordion" role="tablist">
        <b-card no-body v-for="entity in this.entities" :key="entity.id" class="accordion-element">
          <b-card-header header-tag="header" role="tab"  v-b-toggle="'accordion-'+entity.id" class="accordion-header">
            {{entity.name}}
          </b-card-header>
          <b-collapse :id="'accordion-'+entity.id" accordion="entities-accordion" role="tabpanel" class="accordion-content">
            <b-row no-gutters>
              <b-col md="9">
                <b-card-body class="m-3">
                  <b-media>
                    <template #aside>
                      <b-img v-if="entity.image" :src="apiurl+entity.image" class="teaser-image mr-5"></b-img>
                    </template>


                    <img v-if="entity.icon" :src="apiurl+entity.icon.icon" class="icon-image mb-2"/>
                    <b-card-text v-if="entity.location">
                      <b class="headline">Ort</b><br/> <span v-html="entity.location"></span>
                    </b-card-text>

                  </b-media>

                  <b-card-text v-if="entity.description" class="description mt-4">
                    {{entity.description}}
                  </b-card-text>


                </b-card-body>
              </b-col>
              <b-col md="3">
                <b-card-img v-if="entity.floorPlan" :src="apiurl+entity.floorPlan.floorPlan" class="floorplan-image" alt="floorplan" ></b-card-img>
              </b-col>
            </b-row>
          </b-collapse>
        </b-card>
      </div>
      </div>
    </b-col>
    <b-col >
    </b-col>

  </b-row>

</div>

</template>

<script>


export default {
  name: 'entities',
  created: function () {
    this.$store.dispatch('fetchEntities');
  },
  components: {

  },
  data () {
    return {

    }
  },
  methods: {

  },
  computed: {
    entities () {
      return this.$store.state.entities;
    },
    apiurl () {
      return process.env.VUE_APP_API_URL;
    }
  }
}
</script>

<style scoped>
  #entities .maincontent {
    height: 1438px;
    background: var(--unnamed-color-484e67) 0% 0% no-repeat padding-box;
    background: #484E67 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  #entities .accordion-element:nth-child(even) .accordion-header {
    background: var(--unnamed-color-30374b) 0% 0% no-repeat padding-box;
    background: #30374B 0% 0% no-repeat padding-box;

  }
  #entities .accordion-element:nth-child(odd) .accordion-header{
    background: var(--unnamed-color-252833) 0% 0% no-repeat padding-box;
    background: #252833 0% 0% no-repeat padding-box;
  }
  #entities .accordion-header.not-collapsed {
    background: var(--unnamed-color-cf3c42) 0% 0% no-repeat padding-box !important;
    background: #CF3C42 0% 0% no-repeat padding-box !important;
  }
</style>
