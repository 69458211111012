import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource';
Vue.use(VueResource);
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    companies: '',
    entities: '',
    events: '',
    tags: ''
  },
  mutations: {
    updateCompanies (state, data) {
      state.companies = data;
    },
    updateEntities (state, data) {
      state.entities = data;
    },
    updateEvents (state, data) {
      state.events = data;
    },
    updateTags (state, data) {
      state.tags = data;
    },
  },
  actions: {
    async fetchEntities ({ commit } ) {
      return new Promise((resolve, reject) => {
        Vue.http.get(process.env.VUE_APP_API_URL + '/building-entities-light?sort=name')
           .then(response => {
          commit('updateEntities', response.data);
          resolve(response.data);
        }, error => {
          // error callback
          console.log(error);
          reject();
        })
      })
    },
    async fetchEvents ({ commit } ) {
      var today = new Date();
      today.setHours(0,0,0,0);

      var tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      var intervalFrom = new Date(today);
      intervalFrom.setDate(intervalFrom.getDate() - 7);

      return new Promise((resolve, reject) => {
        Vue.http.get(process.env.VUE_APP_API_URL + '/events-light?sort=start' + '&start.greaterThan=' + intervalFrom.toISOString() + '&start.lessThan=' + tomorrow.toISOString())
           .then(response => {
          commit('updateEvents', response.data);
          resolve(response.data);
        }, error => {
          // error callback
          console.log(error);
          reject();
        })
      })
    },
    async fetchCompanies ({ commit } ) {
      return new Promise((resolve, reject) => {
        Vue.http.get(process.env.VUE_APP_API_URL + '/companies?size=100')
           .then(response => {
          commit('updateCompanies', response.data);
          resolve(response.data);
        }, error => {
          // error callback
          console.log(error);
          reject();
        })
      })
    },
    async fetchTags ({ commit }, param ) {
      return new Promise((resolve, reject) => {
        Vue.http.get(process.env.VUE_APP_API_URL + '/companies/' + param.companyId + '/tags')
           .then(response => {
          commit('updateTags', response.data);
          resolve(response.data);
        }, error => {
          // error callback
          console.log(error);
          reject();
        })
      })
    },

  }
})
