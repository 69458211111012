<template>
  <div class="navigation row">
      <div class="col-1">
      </div>
      <div class="col-10">
        <div class="navigation-container">
          <b-nav>
            <b-nav-item to="/companies">
                Mieterverzeichnis
            </b-nav-item>
            <b-nav-item to="/entities">
                Gebäudeorientierung
            </b-nav-item>
            <!-- DEACTIVATED EVENTS -->
            <!--
            <b-nav-item to="/events">
                Anlässe heute
            </b-nav-item>
            -->
          </b-nav>

          <img src="../assets/TPW-Logo-weiss.png" class="tpw-logo" />
          <router-link to="/tpwframe" class="tpw-url bottomright">www.tpw.ch</router-link>

        </div>
      </div>
      <div class="col-1">
      </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
  }
}
</script>

<style scoped>
.navigation {
  height: 482px;

  background: var(--unnamed-color-cf3c42) 0% 0% no-repeat padding-box;
  background: #CF3C42 0% 0% no-repeat padding-box;
  opacity: 1;
}
.navigation ul {
  padding-top: 64px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.navigation li {
  color: var(--unnamed-color-ffffff);
  font-family: "Akko Rounded Pro, Medium";
  font-size: 35px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding: 0px 33px 0px 0px;
}
.navigation li:last-child {
  padding: 0px 0px 0px 0px !important;
}
.navigation a.router-link-active {
  color: #2d2d2d;
}
.navigation a {
  color: #ffffff;
  text-decoration: none;
  padding: 0px;
  margin: 0px;
}
.navigation-container {
  height: 380px;
  position: relative;
}
.tpw-logo {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 401px;
  height: 74px;
  /* UI Properties */
  opacity: 1;
}
.tpw-url {
  position: absolute;
  bottom: 0px;
  right: 0px;
  float: right;
  width: 163px;
  height: 29px;
  color: var(--unnamed-color-ffffff);
  text-align: right;
  font-family: "Akko Rounded Pro, Light";
  font-size: 29px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
</style>
