<template>
<div id="tpwframe">

  <b-row class="maincontent">
    <b-col>
    </b-col>

    <b-col cols="10">
      <div class="maininnercontent" id="iframewrapper">
        <iframe src="https://www.tpw.ch" width="100%" height="100%" sandbox="allow-scripts allow-same-origin" id="iframecontent"></iframe>
      </div>
    </b-col>

    <b-col >
    </b-col>
  </b-row>

</div>

</template>

<script>


export default {
  name: 'tpwframe',
  components: {

  },
  created: function () {
  },
  data () {
    return {
    }
  },
  methods: {

  },
  computed: {
  }
}
</script>

<style>
  #iframecontent {
    overflow: hidden;
  }
  #iframewrapper {
    overflow: hidden;
  }
</style>
