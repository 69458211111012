import Vue from 'vue'
import Router from 'vue-router'
import Companies from './views/Companies.vue'
import Entities from './views/Entities.vue'
import Events from './views/Events.vue'
import TPWFrame from './views/TPWFrame.vue'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/companies',
      name: 'companies',
      component: Companies
    },
    {
      path: '/entities',
      name: 'entities',
      component: Entities
    },
    {
      path: '/events',
      name: 'events',
      component: Events
    },
    {
      path: '/tpwframe',
      name: 'tpwframe',
      component: TPWFrame
    },
    { path: '*', redirect: '/companies' }
  ]
});

window.onpopstate = function() {
  window.history.go();
}

export default router;
