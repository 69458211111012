<template>
<div id="companies">
  <b-row class="maincontent">
    <b-col>
    </b-col>

    <b-col cols="10">
    <div class="maininnercontent scrollbar scrollbar-black ">
      <div class="accordion" role="tablist">
        <b-card no-body v-for="company in this.companies" :key="company.id" class="accordion-element">
          <span v-if="company.firstOccurenceOfCompanyInitial" :id="company.companyInitial"></span>
          <b-card-header header-tag="header" role="tab"  v-b-toggle="'accordion-'+company.id" class="accordion-header" v-on:click="fetchTags(company.id)">
            {{company.companyName}}
          </b-card-header>
          <b-collapse :id="'accordion-'+company.id" accordion="companies-accordion" role="tabpanel" class="accordion-content" >
            <b-row no-gutters>
              <b-col md="9">
                <b-card-body class="m-3">
                  <b-media>
                    <template #aside>
                      <!--
                      <b-img v-if="company.image" :src="apiurl+company.image" class="teaser-image mr-5"></b-img>-->
                      <b-img :src="getCompanyLogo(company)" onerror="this.style.display='none'" class="teaser-image-2 mr-5"></b-img>
                    </template>

                    <b-card-text>
                      <b class="headline">Kontakt</b><br/>
                      <span v-if="company.email">{{company.email}}<br></span>
                      <span v-if="company.telephone">T {{company.telephone}}<br></span>
<!--
                      <span v-if="company.contactPerson">{{company.contactPerson}}<br></span>
                      <span v-if="company.website">{{company.website}}<br></span>
-->
                    </b-card-text>

                    <b-card-text v-if="company.office">
                      <b class="headline">Ort</b><br/> {{company.office}}
                    </b-card-text>

                  </b-media>

                  <b-card-text v-if="company.statement" class="statement mt-4">
                    {{company.statement}}
                  </b-card-text>

                  <b-card-text class="mt-4">
                    <b-link class="tagcloud-link" v-on:click.prevent="showTagcloud">
                      Tag-Cloud
                        <b-icon icon="caret-right-fill" font-scale="1" class="mb-1"></b-icon>
                  </b-link>
                  </b-card-text>
                </b-card-body>
              </b-col>

              <b-col md="3">
                <b-card-img v-if="company.floorplan" :src="apiurl+company.floorplan" class="floorplan-image" alt="floorplan" ></b-card-img>
              </b-col>
            </b-row>
            <b-col md="12" class="tagcloud-background" v-if="show">
              <div class="float-right close-link-wrapper" ><b-link v-on:click.prevent="hideTagcloud" class="close-link">X</b-link></div>

              <div class="wordcloud-wrapper">
                <ListLoader v-if="tags==null || tags=='' || tags.length==0"
                   :speed="2"
                   :animate="true"
                   class="loader"
                 ></ListLoader>
                <vue-word-cloud v-if="tags != null && tags != '' && tags.length>0"
                class="wordcloud"
                style="height: 400px;width: 700px;"
                :words="tags"
                font-family="Helvetica"
                :spacing="0.5"
                :font-size-ratio="5"
                />
              </div>
            </b-col>

          </b-collapse>
        </b-card>

      </div>
    </div>
    </b-col>

    <b-col>
      <div class="alphabet">
        <span v-for="(alphabetValue, alphabetKey) in this.alphabet" :key="alphabetKey">
          <b-link v-if="alphabetValue" v-on:click.prevent="scrollIntoView(alphabetKey)">{{alphabetKey}}</b-link>
          <span v-else>{{alphabetKey}}</span>
          <br>
        </span>
      </div>
    </b-col>
  </b-row>
</div>

</template>

<script>
import VueWordCloud from 'vuewordcloud';
import { ListLoader } from 'vue-content-loader';


export default {
  name: 'companies',
  components: {
    [VueWordCloud.name]: VueWordCloud,
    ListLoader
  },
  created: function () {
    this.$store.dispatch('fetchCompanies');
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    scrollIntoView(alphabetKey) {
      var element = document.getElementById(alphabetKey);
      element.scrollIntoView({behavior: "smooth"});
    },
    fetchTags (companyId) {
      this.show = false;
      this.$store.commit('updateTags', '');
      this.$store.dispatch('fetchTags', { 'companyId': companyId });
    },
    showTagcloud() {
      this.show = true;
    },
    hideTagcloud() {
      this.show = false;
    },
    getCompanyLogo(company) {
      var logoUrl = './logos/';

      if( company.website ) {
        logoUrl += company.website.substr(company.website.indexOf('/') + 1).replaceAll('/en', '').replaceAll('/', '')+'.png';
      } else {
        logoUrl += company.companyName.replaceAll(' ', '')+'.png';
      }

      logoUrl = logoUrl.toLowerCase();
      return logoUrl;
    }
  },
  computed: {
    companies () {
      return this.$store.state.companies;
    },
    tags () {
      var tagArray = [];
      if( this.$store.state && this.$store.state.tags ) {
          var innerArray;
          for( var i in this.$store.state.tags ) {
            innerArray = [];
            innerArray.push(this.$store.state.tags[i].text);
            innerArray.push(this.$store.state.tags[i].weight);
            tagArray.push(innerArray);
          }

      }
      return tagArray;
    },
    alphabet () {
      var initials = {
        'A': false,
        'B': false,
        'C': false,
        'D': false,
        'E': false,
        'F': false,
        'G': false,
        'H': false,
        'I': false,
        'J': false,
        'K': false,
        'L': false,
        'M': false,
        'N': false,
        'O': false,
        'P': false,
        'Q': false,
        'R': false,
        'S': false,
        'T': false,
        'U': false,
        'V': false,
        'W': false,
        'X': false,
        'Y': false,
        'Z': false,
      };
      for( var i in this.$store.state.companies ) {
        initials[ this.$store.state.companies[i].companyInitial] = true;
      }
      return initials;
    },
    apiurl () {
      return process.env.VUE_APP_API_URL;
    }
  }
}
</script>

<style>
  /* default styles in App.vue */

  /* Styles for alphabet */
  .alphabet span {
    color: #2d2d2d;
    font-size: 30px;
    font-family: "Akko Rounded Pro, Medium";

  }
  .alphabet a, .alphabet a:visited, .alphabet a:hover {
    color: #CF3C42;
  }

  .loader{
    height: 400px;
    width: 700px;
    margin-left: 100px;
  }

  .tagcloud-link {
    color: var(--unnamed-color-cf3c42);
    text-align: left;
    font: normal normal medium 20px/28px Akko Rounded Pro;
    font-family: "Akko Rounded Pro, Medium";
    letter-spacing: 0px;
    color: #CF3C42;
    opacity: 1;
  }
  .tagcloud-link:hover {
    color: #CF3C42;
    text-decoration: none;
  }
  .close-link-wrapper {
    margin-top: 20px;
    margin-right: 20px;
  }
  .close-link {
    color: var(--unnamed-color-cf3c42);
    text-align: left;
    font: normal normal medium 20px/28px Akko Rounded Pro;
    letter-spacing: 0px;
    color: #cf3c42;
    opacity: 1;
  }
  .close-link:hover {
    color: #cf3c42;
    text-decoration: none;
  }
</style>
