<template>
  <div id="app">
    <router-view/>
    <Navigation></Navigation>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'

export default {
  name: 'App',
  components: {
    Navigation
  }
}
</script>

<style lang="scss">
  @import '~bootstrap/scss/bootstrap.scss';
  @font-face {
    font-family: "Akko Rounded Pro, Medium";
    src: local("Akko Rounded Pro, Medium"),
     url(./fonts/AkkoRoundedPro-Medium.ttf) format("truetype");
  }
  @font-face {
    font-family: "Akko Rounded Pro, Light";
    src: local("Akko Rounded Pro, Light"),
     url(./fonts/AkkoRoundedPro-Light.ttf) format("truetype");
  }
  @font-face {
    font-family: "ZwoOffcPro, Light";
    src: local("ZwoOffcPro, Light"),
     url(./fonts/ZwoOffcPro-Light.ttf) format("truetype");
  }
  @font-face {
    font-family: "ZwoOffcPro, Semibold";
    src: local("ZwoOffcPro, Semibold"),
     url(./fonts/ZwoOffcPro-Semibold.ttf) format("truetype");
  }
  @font-face {
    font-family: "ZwoScOffcPro, Light";
    src: local("ZwoScOffcPro, Light"),
     url(./fonts/ZwoScOffcPro-Light.ttf) format("truetype");
  }
  @font-face {
    font-family: "ZwoScOffcPro, Semibold";
    src: local("ZwoScOffcPro, Semibold"),
     url(./fonts/ZwoScOffcPro-Semibold.ttf) format("truetype");
  }

  body {
    height: 1920px;
    width: 1080px;
    max-width: 1080px;
    max-height: 1920px;
    overflow-x: hidden;
    overflow-y: hidden;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Styles for main content */
  .card {
    background: transparent;
  }
  .maincontent {
    height: 1438px;
    background: var(--unnamed-color-484e67) 0% 0% no-repeat padding-box;
    background: #454545 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .maininnercontent {
    height: 1238px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .accordion-element {
    margin-bottom: 1px;
    border: 0px;
  }
  .accordion-header {
    height: 70px;
    font-size: 25px;
    font-family: "Akko Rounded Pro, Medium";
    color: #ffffff;
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  }
  .accordion-element:nth-child(even) .accordion-header {
    background: var(--unnamed-color-353535) 0% 0% no-repeat padding-box;
    background: #353535 0% 0% no-repeat padding-box;

  }
  .accordion-element:nth-child(odd) .accordion-header{
    background: var(--unnamed-color-2d2d2d) 0% 0% no-repeat padding-box;
    background: #2d2d2d 0% 0% no-repeat padding-box;
  }
  .accordion-header.not-collapsed {
    background: var(--unnamed-color-cf3c42) 0% 0% no-repeat padding-box !important;
    background: #CF3C42 0% 0% no-repeat padding-box !important;
  }
  .accordion-content {
    background-color: #ffffff;
    font-family: "ZwoOffcPro, Semibold";
    font-size: 22px;
    letter-spacing: 0px;
    color: #8E908F;
  }
  .headline {
    font-family: "ZwoOffcPro, Semibold";
    font-size: 22px;
    letter-spacing: 0px;
    color: #2D2D2D;
  }

  /* Image styles */
  .teaser-image {
    width: 240px;
    height: 210px;
    object-fit: cover;
  }
  .teaser-image-2 {
    width: 240px;
    height: 126px;
    object-fit: cover;
  }
  .floorplan-image {
    width: 258px;
    height: 492px;
    background-color: #efefef;
    object-fit: cover;
    float: right;
  }
  .icon-image {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }

  .tagcloud-background {
    width: 100%;
    background-color: #efefef;

  }
  .tagcloud-background .wordcloud-wrapper {
     padding: 50px 0px;
   }
  .tagcloud-background .wordcloud {
    margin: auto;
  }

  /* Start styles for scrollbars */
  .scrollbar-black::-webkit-scrollbar-track {
    background-color: #2d2d2d;
    border: 5px solid #2d2d2d;
  }
  .scrollbar-black::-webkit-scrollbar {
    width: 25px;
    background-color: #2d2d2d;
  }
  .scrollbar-black::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px 353535;
    border: 4px solid #2d2d2d;
    background-color: #353535;
  }
  .scrollbar-black {
    scrollbar-color: #353535 #2d2d2d;
  }
  /* End styles for scrollbars */

</style>
